.custom-nav-link {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  color: #0f0f0f;
}

.custom-nav-link .custom-icon svg {
  width: 22px;
  height: 22px;
}

.custom-icon-nav-link {
  color: #0f0f0f;
}

/* Dropdown */
.custom-dropdown-menu .dropdown-menu {
  min-width: fit-content;
  left: -18%;
}

.custom-dropdown-menu-sidebar .dropdown-toggle {
  padding: 0 !important;
}

.custom-dropdown-menu-sidebar .dropdown-menu {
  position: fixed !important;
  top: 21%;
  left: 43%;
  box-shadow: 5px 5px 5px rgba(15, 15, 15, 0.1);
}

.custom-dropdown-menu-sidebar .dropdown-menu .custom-nav-link {
  font-size: 14px;
}

.dropdown-toggle::after {
  display: none;
}

.custom-nav-link-button {
  --bs-btn-color: var(--primary-text-clr);
  --bs-btn-bg: var(--primary-clr);
  --bs-btn-hover-color: var(--primary-text-clr);
  --bs-btn-hover-bg: var(--primary-clr);
  --bs-btn-active-color: var(--primary-text-clr);
  --bs-btn-active-bg: var(--primary-clr);
  transition: var(--main-transition);
}

.custom-nav-link-button-sidebar {
  width: 35%;
  margin-left: 3%;
  padding: 1%;
  font-size: 14px;
  margin-top: 2%;
}

.sidebar-link {
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
  font-weight: 500;
  font-size: 14px !important;
  /* line-height: 21px; */
}

.custom-nav-link-icons-container {
  display: flex;
  justify-content: end;
  align-items: end;
  gap: 1em;
  width: 88%;
}

/* .custom-nav-link-icons-container :nth-child(2) {
  margin-left: 5%;
} */

.custom-nav-link-icons-container .custom-icon:first-child svg {
  width: 18px;
  height: 18px;
}

.custom-nav-link-icons-container .custom-icon:last-child svg {
  width: 19px;
  height: 19px;
}

.custom-navbar .nav-link.active,
.custom-icon-nav-link.active {
  color: #fd0606;
}

@media (hover: hover) {
  .custom-nav-link:hover,
  .custom-nav-link:hover .custom-icon,
  .custom-icon-nav-link:hover,
  .custom-icon-nav-link:hover .custom-icon {
    color: #fd0606;
  }

  .custom-nav-link-button {
    --bs-btn-hover-color: var(--primary-clr);
    --bs-btn-hover-bg: var(--primary-text-clr);
  }
}

.custom-account-icon {
  width: 20px;
  height: 20px;
}

.custom-element {
  border: none;
  background-color: transparent;
}

.custom-element.show .custom-account-element,
.custom-dropdown-menu:has(.dropdown-item .nav-link.active)
  .custom-account-element {
  color: #fd0606;
}

.custom-dropdown-menu .dropdown-item:active {
  background-color: transparent;
}

.custom-sign-in-spinner {
  margin-right: 5px;
}

.custom-language-icon {
  width: 26px;
  height: 24px;
  align-self: center;
  cursor: pointer;
}
@media (hover: hover) {
  .custom-language-icon:hover {
    color: #fd0606;
  }
}
.custom-language-icon-in-sidebar {
  width: 17px;
  align-self: unset;
  margin-left: 5%;
}

.fi-button-adjustments {
  width: 40% !important;
}

.custom-user-dropdown .dropdown-menu {
  position: fixed !important;
  width: 22rem;
  top: 12%;
  left: 73%;
  padding: 1%;
}

.custom-dot {
  position: relative;
  bottom: 8px;
  width: 8px;
}

.custom-user-avatar {
  width: 25px;
  height: 25px;
  margin-right: 2px; /* or 2% */
}

@media (max-width: 1200px) {
  .custom-user-dropdown .dropdown-menu {
    left: 68%;
  }
}

@media (max-width: 992px) {
  .custom-user-dropdown .dropdown-menu {
    left: 66%;
    width: 19rem;
  }
}

@media (max-width: 789px) {
  .custom-nav-link {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .custom-user-dropdown .dropdown-menu {
    left: 58%;
  }
}

/* @media (max-width: 734px) {
  .custom-nav-link {
    font-size: 16px;
  }
} */

@media (max-width: 576px) {
  .custom-account-element,
  .custom-dropdown-menu .custom-nav-link {
    font-size: 15px;
  }
}

@media (max-width: 376px) {
  .custom-nav-link-icons-container .custom-icon:first-child svg {
    width: 16px;
    height: 16px;
  }

  .custom-nav-link-icons-container .custom-icon:last-child svg {
    width: 17px;
    height: 17px;
  }
}
