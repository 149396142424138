.custom-navbar {
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(15, 15, 15, 0.2);
    --bs-nav-link-hover-color: #0F0F0F;
}

.custom-navbar > .container {
    max-width: 95%;
}

.custom-navbar .justify-content-end {
    justify-content: space-between !important;
    flex-basis: 45%;
}

@media (max-width: 1200px) {
    .custom-navbar .justify-content-end {
        flex-basis: 53%;
    }
}

@media (max-width: 992px) {
    .custom-navbar .justify-content-end {
        flex-basis: 60%;
    }
}

@media (max-width: 975px) {
    .navbar > .container {
        max-width: 97%;
    }
}

@media (max-width: 768px) {
    .custom-navbar .justify-content-end {
        flex-basis: 68%;
    }
}