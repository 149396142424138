.custom-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  cursor: pointer;
}

.custom-icon img {
  max-width: 100%;
}
