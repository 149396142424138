.custom-sidebar {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    z-index: 9;
    position: fixed;
    top: 70px;
    right: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    background-color: rgba(0.3, 0.3, 0.3, 0.3);
}

.custom-sidebar-in-restaurant {
    top: 0;
    left: 0;
    right: unset;
    flex-direction: row;
    z-index: 1031;
}

.custom-sidebar-content {
    display: flex;
    flex: 0.4;
    justify-content: space-between;
    background-color: #FFF;
    flex-direction: column;
}

.custom-sidebar-content-in-restaurant {
    justify-content: unset;
    padding: 1%;
    border-radius: 0px 8px 8px 0px;
}

.custom-sidebar-nav {
    justify-content: space-around;
    align-items: start;
    border-bottom: 1px solid rgba(15, 15, 15, 0.1);
    padding-bottom: 5%;
    height: 25%;
}

.custom-sidebar-hider {
    flex: 0.6;
}

@media (max-width: 1200px) {
    .custom-sidebar-content-in-restaurant, .custom-sidebar-hider-in-restaurant {
        flex: 0.5;
    }
}

@media (max-width: 768px) {
    .custom-sidebar-content-in-restaurant {
        flex: 0.6;
    }

    .custom-sidebar-hider-in-restaurant {
        flex: 0.4;
    }
}

@media (max-width: 576px) {
    .custom-sidebar-content {
        flex: 0.6;
    }

    .custom-sidebar-hider {
        flex: 0.4;
    }

    .custom-sidebar-content-in-restaurant {
        flex: 0.7 !important;
    }

    .custom-sidebar-hider-in-restaurant {
        flex: 0.3 !important;
    }
}

@media (max-width: 451px) {
    .custom-sidebar-content {
        flex: 0.7;
    }

    .custom-sidebar-hider {
        flex: 0.3;
    }

    .custom-sidebar-content-in-restaurant {
        flex: 0.9 !important;
    }

    .custom-sidebar-hider-in-restaurant {
        flex: 0.1 !important;
    }
}

@media (max-width: 376px) {
    .custom-sidebar-content {
        flex: 0.8;
    }

    .custom-sidebar-hider {
        flex: 0.2;
    }

    /* .custom-sidebar-content-in-restaurant {
        flex: 0.8;
    }

    .custom-sidebar-hider-in-restaurant {
        flex: 0.2;
    } */
}

@media (max-width: 320px) {
    .custom-sidebar-content {
        flex: 0.7;
    }
}