.custom-info-div {
    display: flex;
    justify-content: space-between;
    padding-right: 15%;
}

.custom-info-div-sidebar {
    padding-top: 0 !important;
    flex-direction: column;
    align-items: start;
    padding-left: 1%;
    padding-bottom: 3%;
    row-gap: 0 !important;
}

.custom-info-div-sidebar .custom-list-group {
    align-items: start;
    margin-bottom: 3% !important;
}

.custom-info-div-sidebar .custom-list-text, .custom-info-div-sidebar .svg {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.custom-info-div-sidebar .custom-list-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.custom-info-div-sidebar .custom-list-item {
    padding: 5px;
}

.custom-anchor-tag-sidebar {
    padding-left: 2%;
}

@media (max-width: 992px) {
    .custom-info-div {
        padding-right: 0;
    }
}

@media (max-width: 768px) {
    .custom-info-div {
        padding-right: 4%;
    }
}

@media (max-width: 587px) {
    .custom-info-div {
        padding-right: 0;
    }
}

@media (max-width: 568px) {
    .custom-info-div {
        flex-wrap: wrap;
        row-gap: 10px;
        padding-right: 10%;
    }
}

@media (max-width: 451px) {
    .custom-info-div-sidebar .custom-list-item {
        padding: 7px;
    }
}

@media (max-width: 426px) {
    .custom-info-div {
        padding-right: 0;
        justify-content: center;
        text-align: center;
        padding-top: 5%;
    }
}

@media (max-width: 360px) {
    .custom-info-div {
        text-align: center;
    }
}

@media (max-width: 320px) {
    .custom-info-div {
        justify-content: center;
        text-align: center;
    }
}