.custom-burger-button {
    transform: scale(0.7);
    border: none;
    --bs-btn-color: #0F0F0F;
    --bs-btn-bg: transparent;
    --bs-btn-hover-color: #0F0F0F;
    --bs-btn-hover-bg: transparent;
    --bs-btn-active-color: #0F0F0F;
    --bs-btn-active-bg: transparent;
}
@media (hover: hover) {
    .custom-burger-button:hover {
        opacity: 0.5;
    }
}

.custom-burger-button-icon {
    transition: transform 0.3s ease-in-out
}

.custom-close-icon {
    width: 24px;
    height: 24px;
}

@media (max-width: 320px) {
    .custom-burger-button {
        transform: scale(0.6);
    }
}